@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: var(--font-inter);
    }

    :root {
        --navigation-height: 3rem;
        --navigation-top: 1rem;
        --page-max-width: 1280px;
        --page-padding-top: 4rem;
        --page-padding-default: 2rem;
        --page-padding-left: var(--page-padding-default);
        --page-padding-right: var(--page-padding-default);
        --page-edge: calc(
            max(var(--page-max-width), 100vw) / 2 - var(--page-max-width) / 2 +
                var(--page-padding-left)
        );
        --layer-navigation: 30;
        --layer-footer: 25;
        --page-max-content: 42rem;
        --page-docs-content: 44rem;
        --page-docs-menu: 17rem;
        --page-docs-padding: 17rem;
        --padding: 1rem;
    }

    .light {
        --color-primary: #232323;
        --color-secondary: #0f172a;
        --color-tertiary: #475569;
        --color-accent: #2563eb;
        --hover-color-primary: rgba(12, 14, 17, 0.578);
        --hover-color-secondary: rgba(15, 15, 52, 0.848);

        --color-text-primary: #09090b; /* zinc-950 */
        --color-text-secondary: #27272a; /* zinc-800 */
        --color-text-tertiary: #52525b; /* zinc-600 */
        --color-text-quaternary: #71717a; /* zinc-500 */

        --color-bg-primary: #fff;
        --color-bg-secondary: #fafafa; /* zinc-50 */
        --color-bg-tertiary: #f4f4f5; /* zinc-100 */
        --color-bg-quaternary: #e4e4e7; /* zinc-200 */
        --color-bg-quinary: #e5e7eb; /* gray-200 */
        --color-bg-translucent: hsla(0, 0%, 100%, 0.05);

        --color-border-primary: #e5e7eb; /* gray-200 */
        --color-border-secondary: #e4e4e7; /* zinc-200 */
        --color-border-tertiary: #d4d4d8; /* zinc-300 */
        --color-border-quaternary: ;
        --color-border-translucent: hsla(0, 0%, 100%, 0.05);
    }

    .dark {
        --color-primary: #f1f5f9;
        --color-secondary: #f1f5f9;
        --color-tertiary: #cbd5e1;
        --color-accent: #e2e8f0;
        --hover-color-primary: rgba(226, 239, 255, 0.25);
        --hover-color-secondary: rgb(215, 215, 215);

        --color-text-primary: #fafafa; /* zinc-50 */
        --color-text-secondary: #e4e4e7; /* zinc-200 */
        --color-text-tertiary: #a1a1aa; /* zinc-400 */
        --color-text-quaternary: #71717a; /* zinc-500 */

        --color-bg-primary: #000;
        --color-bg-secondary: #09090b; /* zinc-950 */
        --color-bg-tertiary: #18181b; /* zinc-900 */
        --color-bg-quaternary: #27272a; /* zinc-800 */
        --color-bg-quinary: #3f3f46; /* zinc-700 */
        --color-bg-translucent: hsla(0, 0%, 100%, 0.05);

        --color-border-primary: #18181b; /* zinc-900 */
        --color-border-secondary: #27272a; /* zinc-800 */
        --color-border-tertiary: #3f3f46; /* zinc-700 */
        --color-border-translucent: hsla(0, 0%, 100%, 0.05);
    }

    @media screen and (max-width: 1024px) {
        :root {
            --page-padding-default: 1rem;
        }
    }
}

@layer utilities {
    .text-shadow {
        text-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    }

    .light .text-gradient {
        background: linear-gradient(to right bottom, rgb(0, 5, 5) 32%, rgba(255, 255, 255, 0.38))
            text padding-box;
        color: transparent;
    }

    .dark .text-gradient {
        background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.92) 68%, transparent)
            text padding-box;
        color: transparent;
    }

    .light .background-gradient {
        background: radial-gradient(
            ellipse 80% 50% at 50% -20%,
            rgb(131, 24, 67, 0.1),
            transparent
        );
    }

    .dark .background-gradient {
        background: radial-gradient(
            ellipse 80% 50% at 50% -20%,
            rgb(120, 119, 198, 0.3),
            transparent
        );
    }

    .hero-gradient-light {
        background: radial-gradient(
                ellipse 50% 80% at 20% 40%,
                rgba(93, 52, 221, 0.1),
                transparent
            ),
            radial-gradient(ellipse 50% 80% at 80% 50%, rgba(120, 119, 198, 0.15), transparent);
    }

    .hero-gradient-dark {
        background: radial-gradient(
                ellipse 50% 80% at 20% 40%,
                rgba(93, 52, 221, 0.1),
                transparent
            ),
            radial-gradient(ellipse 50% 80% at 80% 50%, rgba(120, 119, 198, 0.15), transparent);
    }

    .hero-glow-gradient-light {
        background: conic-gradient(
            from 230deg at 51% 52%,
            rgba(29, 78, 216, 1) 0%,
            rgba(59, 130, 246, 1) 67deg,
            rgba(147, 51, 234, 1) 200deg,
            rgba(55, 48, 163, 1) 252deg,
            rgba(99, 102, 241, 1) 302deg,
            rgba(91, 33, 182, 1) 360deg
        );
    }

    .hero-glow-gradient-dark {
        background: conic-gradient(
            from 230.29deg at 51.63% 52.16%,
            rgba(29, 78, 216, 1) 0%,
            rgba(59, 130, 246, 1) 67deg,
            rgba(147, 51, 234, 1) 200deg,
            rgba(55, 48, 163, 1) 252deg,
            rgba(99, 102, 241, 1) 302deg,
            rgba(91, 33, 182, 1) 360deg
        );
    }

    .hero-glow-lines-light {
        background: linear-gradient(
            var(--direction),
            rgba(59, 130, 246, 0) 42%,
            #3b82f6 88%,
            #60a5fa 95%
        );
    }

    .hero-glow-lines-dark {
        background: linear-gradient(
            var(--direction),
            #9d9bf2 0.43%,
            #7877c6 14.11%,
            rgba(120, 119, 198, 0) 62.95%
        );
    }

    .radial-faded-light {
        background: radial-gradient(circle at bottom center, var(--color), transparent 70%);
    }

    .radial-faded-dark {
        background: radial-gradient(circle at bottom center, var(--color), transparent 70%);
    }

    .mask-radial-faded-light {
        mask-image: radial-gradient(circle at center center, white, transparent 80%);
    }
    .mask-radial-faded-dark {
        mask-image: radial-gradient(circle at center center, black, transparent 80%);
    }

    .light .background-radial {
        background: radial-gradient(
            ellipse 100% 40% at 50% 60%,
            rgba(95, 105, 115, 0.01),
            transparent
        );
    }

    .dark .background-radial {
        background: radial-gradient(
            ellipse 100% 40% at 50% 60%,
            rgba(95, 105, 115, 0.12),
            transparent
        );
    }

    .light .background-linear-gradient {
        background: linear-gradient(
            180deg,
            rgba(135, 150, 165, 0),
            rgba(135, 150, 165, 0.1) 20%,
            rgba(135, 150, 165, 0.1) 80%,
            rgba(135, 150, 165, 0)
        );
    }

    .dark .background-linear-gradient {
        background: linear-gradient(
            180deg,
            rgba(95, 105, 115, 0),
            rgba(95, 105, 115, 0.1) 20%,
            rgba(95, 105, 115, 0.1) 80%,
            rgba(95, 105, 115, 0)
        );
    }

    .glass-gradient-light {
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    }

    .glass-gradient-dark {
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    }

    .scrollbar-hide {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    .backdrop-6px {
        backdrop-filter: blur(6px);
    }

    .mask-image-black {
        mask-image: linear-gradient(black, transparent);
    }

    .conic-gradient-light {
        background: conic-gradient(from 90deg at 90% 50%, var(--top), var(--bottom)),
            conic-gradient(from 270deg at 10% 50%, var(--bottom), var(--top));
        mask-image: radial-gradient(75% 35%, white, transparent);
        background-position:
            0% 0%,
            100% 0%;
        background-size:
            50% 100%,
            50% 100%;
        background-repeat: no-repeat;
    }

    .conic-gradient-dark {
        background: conic-gradient(from 90deg at 80% 50%, var(--top), var(--bottom)),
            conic-gradient(from 270deg at 20% 50%, var(--bottom), var(--top));
        mask-image: radial-gradient(100% 50%, black, transparent);
        background-position:
            1% 0%,
            99% 0%;
        background-size:
            50% 100%,
            50% 100%;
        background-repeat: no-repeat;
    }

    .card-glass-gradient {
        background:
            linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)) padding-box,
            linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 120%) border-box;
        border: '1px solid transparent';
    }

    /* Common styles for all logos */
    .logo {
        opacity: 0;
        transition:
            opacity 1s ease-in-out,
            transform 1s ease-in-out,
            filter 1s ease-in-out;
        transform: scale(0.8);
        filter: blur(5px);
        animation: fadeInOut 12s infinite;
    }

    /* <details> + <summary> */
    details {
        user-select: none;
        & summary svg {
            transform: rotate(90deg);
        }
    }
    details[open] {
        & summary svg {
            transform: rotate(90deg);
        }
    }

    details[open] summary ~ * {
        animation: ease-opacity-t-b 0.5s ease;
    }

    summary {
        cursor: pointer;
    }
    svg {
        transition: all 0.3s;
    }

    summary::-webkit-details-marker {
        display: none;
    }

    /* Keyframes for fading, scaling, and filtering animation */
    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: scale(0.8);
            filter: blur(5px);
        }
        3%,
        30% {
            opacity: 1;
            transform: scale(1);
            filter: blur(0);
        }
        33% {
            opacity: 0;
            transform: scale(0.8);
            filter: blur(5px);
        }
        100% {
            opacity: 0;
            transform: scale(0.8);
            filter: blur(0px);
        }
    }

    .marquee {
        max-width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        --speed: 25s;
        --item-width: 350px;
        --item-gap: 20px;

        --single-slide-speed: calc(var(--speed) / var(--num-items));
        --item-width-plus-gap: calc(var(--item-width) + var(--item-gap));
        --track-width: calc(var(--item-width-plus-gap) * calc(var(--num-items) - 1));
    }

    .marquee-track {
        container-type: inline-size;
        display: grid;
        grid-template-columns: var(--track-width) [track] 300px [resting];
        width: max-content;
        height: 200px;
    }

    .marquee-item {
        width: var(--item-width);
        height: 50%;
        aspect-ratio: 1/1.2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        grid-area: resting;
        animation: marquee-move var(--speed) linear infinite var(--direction, forwards);
        animation-delay: calc(var(--single-slide-speed) * var(--item-position) * -1);
    }

    .marquee-item svg {
        height: auto;
        width: 40%;
    }

    .logoMarquee {
        width: 140px; /* Pas de breedte aan zoals nodig */
        height: auto; /* Zorg ervoor dat de hoogte proportioneel blijft */
        object-fit: contain; /* Zorg ervoor dat de afbeelding binnen de gegeven breedte en hoogte past */
    }

    @keyframes marquee-move {
        to {
            transform: translateX(calc(-100cqw - 100%));
        }
    }

    @keyframes infiniteScroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-100% / 2));
        }
    }

    .animate-infinite-scroll {
        display: inline-flex;
        animation: infiniteScroll 50s linear infinite;
    }

    .border-dashed {
        height: 100%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding-bottom: 0;
        border-radius: 1rem 0 0 1rem;
        border: dashed;
        border-width: 1px;
        border-color: rgb(229 231 235);
    }

    .bg-stripes-gray {
        background-color: #ffffff00;
        background-image: linear-gradient(
            135deg,
            #e5e7eb 10%,
            #0000 0,
            #0000 50%,
            #e5e7eb 0,
            #e5e7eb 60%,
            #0000 0,
            #0000
        );
        background-size: 7.07px 7.07px;
    }

    .bg-stripes-gray-dark {
        background-color: #ffffff00;
        background-image: linear-gradient(
            135deg,
            #262628 10%,
            #0000 0,
            #0000 50%,
            #262628 0,
            #262628 60%,
            #0000 0,
            #0000
        );
        background-size: 7.07px 7.07px;
    }

    .slide-center {
        transform: translateX(var(--page-edge));
    }

    .slide-p-r {
        padding-right: calc(var(--page-edge) * 2);
    }
    .listIntegrations {
        opacity: 0;
        transform: translateY(20px); /* Begin iets lager */
        transition:
            opacity 0.5s ease-out,
            transform 0.5s ease-out;
    }

    .listIntegrations.opacity-100 {
        opacity: 1;
        transform: translateY(0); /* Normale positie */

        .blur-container {
            position: relative;
            overflow: hidden;
        }

        .blur-content {
            position: relative;
            z-index: 1;
        }
    }
    @layer components {
    }
    .patientlist button:focus-visible {
        outline: none;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5); /* Blauwe ring */
        border-color: rgba(59, 130, 246, 0.5); /* Optionele border */
    }
    :focus-visible {
        outline: none;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 1);
        border-color: rgba(59, 130, 246, 1);
    }
    input[type='checkbox']:focus-visible {
        outline: 2px solid rgba(59, 130, 246, 1);
    }
}
